export enum RoleEnum {
  /// <summary>
  /// No permissions.
  /// </summary>
  None = 0,

  /// <summary>
  /// Can view items of the entity its attached to.
  /// </summary>
  View = 1 << 1,

  /// <summary>
  /// Can view and edit items of the entity its attached to.
  /// </summary>
  Edit = View | (1 << 2),

  /// <summary>
  /// Can view, edit, and create items of the entity its attached to.
  /// </summary>
  /// <returns></returns>
  Create = Edit | (1 << 3),

  /// <summary>
  /// Can view, edit, and delete items of the entity its attached to.
  /// </summary>
  /// <returns></returns>
  Delete = Edit | (1 << 4),

  /// <summary>
  /// Can view, edit, create, delete, and manage users the entity its attached to.
  /// </summary>
  Manage = Create | Delete | (1 << 5),

  /// <summary>
  /// Can view, edit, create, delete, and manage users the entity its attached to.
  /// This role can only be assigned/removed by an Admin (TVS).
  /// </summary>
  SuperUser = Manage | (1 << 6),

  /// <summary>
  /// TVS-Only. Can do anything.
  /// </summary>
  Admin = Manage | (1 << 16),
}

export function parseRoleEnum(str: string): RoleEnum | undefined {
  return Object.values(RoleEnum).includes(str) ? (<any>RoleEnum)[str] : undefined;
}

// export function RoleEnumParse(value: string): RoleEnum {
//   const x: RoleEnum = (<any>RoleEnum)[value];
//   return x;
// }
